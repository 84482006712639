<input type="hidden" [formControl]="dynamicSelect" [formlyAttributes]="field" />

<div #selectWrapper>
    <div *ngIf="this.showBy != 'no'">
        <label class="filterLabelStyle" style="margin: 0 !important; z-index: 9;">{{label||"NO Label"}}</label>
    
        <div *ngIf="selectedAll" class="selectedAllLabel">All</div>

        <!-- Select Dropdown -->
        <ng-select class="ngSelection du-new-ngselect-multiselect" [class.du-new-ngvalue-select-all-multiselect]="selectAllBtnSelected" [class.du-new-placeholder-multiselect]="selectedAll" style="font-size:13px; font-family: 'Poppins'" #sc [multiple]="true"
            [clearable]="false" [items]="option" [(ngModel)]="selectedItems" [placeholder]="this.placeholder"
            [selectableGroup]="true" [closeOnSelect]="false" [dropdownPosition]="'bottom'" (remove)="selectOption()"
            (add)="selectOption()" (close)="onClose()" [bindLabel]="this.labelProp">
    
            <!-- Search Of the Filter -->
            <ng-template ng-header-tmp>
                <div style="position: relative;">
                    <mat-icon class="du-mat-search">search</mat-icon>
                    <input class="searchFiler" type="text" (input)="sc.filter(onFilterTextBoxChanged($event))"
                        placeholder="Search" />
                </div>
    
                <!-- Select possible -->
                <!-- <div class="form-check" *ngIf="!selectAllBtn">
                    <button mat-stroked-button (click)="possibleSelection()">Select possible</button>
                </div> -->
                
                <!-- Select Alternative -->
                <!-- <div class="form-check" *ngIf="selectAllBtn && this.selectedItems.length!=0 && !(this.selectedItems.length==this.option.length)">
                    <button mat-stroked-button (click)="selectAlternative()">Select Alternative</button>
                </div> -->
                
                <!-- Data Excluded -->
                <!-- <div class="form-check" *ngIf="selectAllBtn && this.selectedItems.length!=0 &&!(this.selectedItems.length==this.option.length)">
                    <button mat-stroked-button (click)="selectExcluded()">Select Excluded</button>
                </div> -->

                <!-- Excel Option to adding previos filter -->
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [ngModel]="isAddChecked" (click)="selectAddClick()">
                    <label class="form-check-label" style="cursor: pointer;" for="flexCheckDefault" (click)="selectAddClick()">
                        {{'Add current selection to filter'}}
                    </label>
                </div>

                <div class="form-check mr-2" *ngIf="selectAllBtn">
                    <input class="form-check-input" type="checkbox" [ngModel]="isChecked" (click)="selectAllClick()">
                    <label class="form-check-label" style="cursor: pointer;" for="flexCheckDefault" (click)="selectAllClick()">
                        {{ selectAllLabel }}
                    </label>
                </div>
            </ng-template>
    
            <!-- Visible of the Filter -->
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" let-index="index">
                <div class="ng-value" *ngFor="let item of selectedItems  | slice: 0:itemsShowLimit">
                    <span class="ng-value-label">{{ item[this.labelProp]}}</span>
                </div>
                <div class="ng-value" *ngIf="selectedItems.length > itemsShowLimit">
                    <span class="ng-value-label">{{ selectedItems.length - itemsShowLimit }} more...</span>
                </div>
            </ng-template>
    
            <!-- Selected Check Box AND Lable name -->
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
                <div class="form-check" style="margin: 0 0 -5px;">
                    <input class="form-check-input" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                        [value]="item">
                    <label [matTooltip]="item[this.toolTipKey]" [matTooltipPosition]="'above'"
                        matTooltipClass="example-tooltip-multi" class="form-check-label du-opt"
                        for="flexCheckDefault">
                        {{item[this.labelProp] }}
                    </label>
                </div>
            </ng-template>
    
            <!-- Apply Button -->
            <ng-template ng-footer-tmp>
                <div class="d-flex align-items-center">
                    <button (click)="applyFilter(sc)" class="btn btn-sm btn-secondary buttonApply" [disabled]="(this.selectedItems.length!=this.option.length) && (this.selectedItems.length>this.userCanSelect) && !isChecked">
                        Apply
                    </button>
                    <span style="color: red;" *ngIf="(this.selectedItems.length!=this.option.length) && (this.selectedItems.length>this.userCanSelect) && !isChecked">{{ 'Select only ' + this.userCanSelect + ' items or select all.' }}</span>
                </div>
            </ng-template>
        </ng-select>
        <hr>
    </div>
</div>

