// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    typeOfEnv: 'DEMO',
    production: true, 
    configType: false,
    topAlertSetting:true,
    logoUrl:'https://www.dataunveil.com/assets/img/logo.png',
    apiBaseUrl:'https://insights-demo-api.dataunveil.com',
    widgetConfigEndPoint : 'kpi/get-widget-details-by-id',
    apiListEndPoint:'kpi/get-widget-details',
    apiColumnListEndPoint: 'kpi/get-list-of-value',
    getListOfWork:'kpi/get-list-of-work',
    getWorkDetails : 'kpi/get-work-details-by-id',
    addNewWorkList: 'kpi/insert-work-layout',
    infoIconText:"FRMPortal/get-info-text",
    AMPLIFY: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_IBRNnm00y',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      userPoolWebClientId: '2oubd6j8aae431u2disld0fnge',
    },
    // AMPLIFY: {
    //   region: 'us-west-2',
    //   userPoolId: 'us-west-2_co0jXCUyR',
    //   authenticationFlowType: 'USER_PASSWORD_AUTH',
    //   userPoolWebClientId: '3ulas8hp6hlb8f3jvu17dokg93',
    // },
    pageAddEditEndPoint: 'kpi/insert-page-details',
    pageListEndPoint: 'kpi/get-page-list',
    ConfigTypeEndPoint: "kpi/get-config-type",
    EnvironmentEndPoint:"kpi/get-environment-type",
    
    getUserInfo : "api/get-user-info",
    
    patientListFRM: 'FRMPortal/get-patient-list',
    getCycleData : "kpi/get-execution-details",
    getBrandDate : "KPI/get-brand-date",
    MixPanelToken:"7ed5424106e8cf5d9cf62314f2b2ef55",
    MixPanelTrack:false
 
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  