import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FilterService } from 'src/app/services/filter.service';
import { FormControl } from '@angular/forms';
import { Subscription, debounceTime } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserActivityService } from 'src/app/services/useractivity.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CdkOverlayOrigin, FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import _ from 'lodash';

@Component({
  selector: 'widget-new-multi-select',
  templateUrl: './new-multi-select.component.html',
  styleUrls: ['./new-multi-select.component.scss']
})
export class DynamicNewMultiSelect extends FieldType implements OnInit, OnDestroy {    //implements OnInit

  override field: any;
  
  dynamicSelect = new FormControl()
  selectedItems: any[] = [];
  label: any
  isOpen: any = null
  selectAllLabel = 'Select All';
  isChecked = false;
  option: any
  opt: any; placeholder: any
  onValueChangeUpdate: any
  dropdownSettings = {}
  dropdownList = []
  itemsShowLimit = 2
  labelProp: any
  valueProp = '';
  reqSubcription: Subscription[] = []
  cardName: any
  cardId: any
  cardPage: any
  statusFromUrl: any
  isCrossed = false
  reasonFromUrl: any;
  showBy: any
  queryFilterData: any
  resetURLData = false;
  addParameter: any;
  isAddChecked = false;
  dashboardKey: any;
  defalutOption: any[] = [];
  previousSelection: any = []
  currentSelection: any = []
  enteredText: any;
  firstTime = true;
  toolTipVisible:any;
  selectAllAsNull=false;
  defaultFilter:any;
  selectAllBtn = true;
  isOpenOverlay = false;
  selectedAll = false;
  selectAllBtnSelected = true;
  defaultAllOption:any;
  userCanSelect = 3;
  // Define a variable to hold the subscription
  private parentControlSubscription: Subscription | undefined;
  trigger!: CdkOverlayOrigin | FlexibleConnectedPositionStrategyOrigin;

  @ViewChild('selectWrapper', { static: false }) selectWrapper!: ElementRef;

  
  constructor(public filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute,
    private userActivityService: UserActivityService,
    private navigationService: NavigationService,
    private dashboardService: DashboardService
  ) { super() }

  shouldDisableDiv(item$: any, index: number): boolean {
    return this.selectedItems.length >= this.userCanSelect && !item$.selected;
  }

  // Excel Filter to get data
  onFilterTextBoxChanged($event: any) {
    this.enteredText = $event.target.value.toLowerCase();
    if(this.enteredText) {
      this.selectAllBtn = false;
    } else {
      this.selectAllBtn = true;
    }
    
    let option = this.defaultAllOption.filter((f: any) => f[this.labelProp].toString().toLowerCase().includes(this.enteredText));
    
    if(this.enteredText) {
      this.option = _.concat(option, this.selectedItems);
      let combinedOptions = _.concat(option, this.selectedItems);
      this.option = _.uniqBy(combinedOptions, this.labelProp);

      this.isChecked = (this.selectedItems.length == this.option.length) ? true : false;
      return '';
    } else {
      this.option = [...this.defaultAllOption];
      this.isChecked = (this.selectedItems.length == this.option.length) ? true : false;
    }

    
    return ($event.target as HTMLInputElement).value;
  }

  toolTipKey: any;

  // Data present in the drop down or not
  areAllObjectsPresent(array1: any[], array2: any[], property: string): boolean {
    return array1.every(obj1 => array2.some(obj2 => obj2[property] == obj1[property]));
  }
  queryParam: any;
  tabId:any;

  ngOnInit() {

    // Route
    this.route.params.subscribe((p: any) => {
      let cardName = p["cardName"];
      // Query Params
      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.tabId = params["tab_id"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.cardId = decryptedParams["cardId"] || '';

        if (cardName || params?.['record'] == 'yes') {
          if (sessionStorage.getItem("filterData")) {
            let filter: any = sessionStorage.getItem("filterData")
            this.queryFilterData = JSON.parse(filter) || {};
          } else {
            this.queryFilterData = '';
          }
        } else {
          this.queryFilterData = '';
        }
      });
    });

    this.route.params.subscribe((p: any) => {
      // this.cardName = p["cardName"];
      // this.cardId = p["cardId"];

      // this.dashboardKey = p["dashboardKey"];

      // if(sessionStorage.getItem('net_status_flr'))
      // let status:any = sessionStorage.getItem('net_status_flr')
      //   if(status)
      //     this.queryFilterData = JSON.parse(status);
      // // for KPI Control chart
      // if(this.cardName == 'pwt') {

      //   let status:any = sessionStorage.getItem('net_status_flr')
      //   if(status)
      //     this.queryFilterData = JSON.parse(status);
      // } else if (this.cardName == 'recdetail') {
      //   let rec:any = sessionStorage.getItem('rec_detail_flr')
      //   this.queryFilterData = rec ? JSON.parse(rec) : '';
      // } else if(this.cardId=='multi'){

      //   this.route.queryParams.subscribe((r:any)=> {
      //     this.queryFilterData = r ? r : {}
      //   })

      //   // let newData = sessionStorage.getItem('selectedKPIcontrol');
      //   // this.queryFilterData = newData ? JSON.parse(newData)   : '';

      // } else {
      //   this.queryFilterData =  '';
      // }
    })


    // When Filter is Reseted
    this.reqSubcription.push(this.filterService.formReset.subscribe((res: any) => {

      this.isChecked = true;
      this.isCrossed = true;
      this.resetURLData = true;
      this.selectedItems = this.option;

      this.filterService.previousSelectedItems[this.field.key] = this.option;
      this.filterService.selectedItem['multi'][this.field.key] = this.option;
      this.filterService.allOptionData['multi'][this.field.key] = this.option;

      if (this.addParameter) {
        this.filterService.duplicateParameter[this.addParameter] = this.stringifyMode(this.selectedItems, this.addParameter)
      }

      if (this.field?.parentKey == "") {
        this.field.formControl?.setValue(this.selectedItems)
      }

      this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
    }))

    this.label = this.field.templateOptions.label
    this.placeholder = this.field.templateOptions.placeHolder
    this.showBy = this.field.templateOptions.showBy
    this.opt = this.field.templateOptions || {};
    let ds = this.opt.dataSourceUrl
    this.itemsShowLimit = 2
    this.labelProp = this.opt.labelProp;
    this.valueProp = this.opt.valueProp;
    this.addParameter = this.opt.addParameter;
    this.toolTipKey = this.opt.toolTipVisible || this.labelProp;
    this.selectAllAsNull = this.opt.selectAllAsNull == 'yes' ? true : false;
    this.defaultFilter = this.opt.defaultFilter;
    this.userCanSelect = this.opt.userCanSelect || 100;

    // Inside your code
    if (this.field?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.field.parentKey);
        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];

          // to empty for child model
          let key = this.key as string
          let fndChild: any = this.filterService.globalFilterFields.find((res: any) => key == res.parentKey)
          if (fndChild) {
            this.model[fndChild.key] = '';
            // User State
            // let ab = { [fndChild.key]: []}
            // this.userActivityService.setData({ "selectedFilters": ab })

            // delete this.filterService.previousSelectedItems?.[fndChild.key]
          }

          if (this.fndParent()) {
            if (ds == "") {
              this.opt.options = val;
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp]);
              this.field.formControl?.setValue(v);
            } else {
              this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.model);
            }
          }
        });
        this.opt.options = [];
      };
    } else {
      this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.model);
    }
  }

  // Is Object or Array
  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  // Find the Parent Key 
  fndParent() {

    let data: any;
    if (this.model[this.field.parentKey].length) {
      data = this.model[this.field.parentKey].find((r: any) => r[this.field.parentKey])
    } else if (this.isObjectNotArray(this.model[this.field.parentKey])) {
      data = this.model[this.field.parentKey][this.field.parentKey]
    } else if (this.model[this.field.parentKey]) {
    }

    return data
  }

  // Load Api
  loadApiData(method: string, url: string, key: string, data: any) {
    this.reqSubcription.push(this.filterService.loadDataMultiselection(method, url, key, data, this.dashboardService.dashboardKey).subscribe((data: any) => {

      if (data.length > 0) {
        
        this.option = data ? data : [];
        this.defaultAllOption = data ? data : [];
        
        // Default filter from designer
        if(this.defaultFilter) {
          let spltString = this.defaultFilter.split(',');
          let flrData = this.option.filter((f:any)=> spltString.find((o:any)=> o.toLowerCase() == f[this.key as string].toLowerCase()));
          this.option = [...flrData];
        }

        this.defalutOption = this.option;

        // Back up all data
        this.filterService.backupAllSingleAndMulti[this.field.key] = this.option;

        // Toget selected Source type from the configuration 
        let abcData: any = [];
        let comparedArray: any = [];
        if (this.filterService.selectedFilterValue?.['is_Selected'] == true) {
          abcData = this.filterService.selectedFilterValue['all_source_type'].filter((res: any) => 'source_type' == this.key && res.selected == true)
          if (abcData.length > 0) {
            comparedArray = abcData.filter((obj1: any) => data.some((obj2: any) => obj2[this.key as string].toString() == obj1[this.key as string].toString()));
          }
        }

        if (this.queryFilterData instanceof Array && this.queryFilterData?.length) {
          let selectedData: any = this.queryFilterData.find((o: any) => o.filterKey?.includes(this.field.key));
          if (selectedData) {
            const searchResult = this.option.find((obj: any) => {
              for (let key in obj) {
                if (obj[key].toString().toLowerCase() == selectedData[selectedData.key].toLowerCase()) {
                  return true;
                }
              }
              return false;
            });
            this.queryFilterData = searchResult;
          };
        }

        // from url
        let ifQuery: any;
        if (this.queryFilterData) {
          let queryFilter = Object.entries(this.queryFilterData).map(([key, value]) => ({ key, value }));
          ifQuery = queryFilter.find((fnd: any) => fnd.key == this.field.key)
        }

        // User State
        if (ifQuery && this.queryFilterData) {
          let comparedArrayW = this.queryLoaded(ifQuery['value'])
          if (comparedArrayW.length > 0) {
            if (this.cardId == 'multi') {
              this.option = comparedArrayW
              this.selectedItems = comparedArrayW
              this.filterService.allOptionData['multi'][this.field.key] = this.selectedItems
            } else {
              this.selectedItems = comparedArrayW
              this.filterService.allOptionData['multi'][this.field.key] = this.option
            }
          } else {
            this.selectedItems = this.userStoredData();
            this.filterService.allOptionData['multi'][this.field.key] = this.option
          }
        } else {
          if (comparedArray.length > 0) {
            this.option = comparedArray;
            this.selectedItems = this.userStoredData();
          } else {
            this.selectedItems = this.userStoredData();
          }
          this.filterService.allOptionData['multi'][this.field.key] = this.option
        }


        // If length is same all should checked
        if (this.selectedItems.length == this.option.length) {
          this.isChecked = true
        } else {
          this.isChecked = false
        }


        // Add Parameter like source key like all the key
        if (this.addParameter) {
          this.filterService.duplicateParameter[this.addParameter] = this.stringifyMode(this.selectedItems, this.addParameter)
        }

        this.filterService.selectedItem['multi'][this.field.key] = this.selectedItems
        this.filterService.previousSelectedItems[this.field.key] = this.selectedItems
        
        if(this.selectAllAsNull) {
          this.filterService.selectAllAsNull.push([this.field.key])
        }

        if(this.selectedItems.length==this.option.length) {
          this.selectedItems = [];
          this.isChecked = false;
          this.selectedAll = true;
          this.selectAllBtnSelected = true;
          this.field.formControl?.setValue(null);
          this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
        } else {
          this.selectedAll = false;
          this.selectAllBtnSelected = false;
          this.field.formControl?.setValue(this.selectedItems)
          this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
        }

        // this.disabledOption();

        // this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
      } else {
        // when there is no data in filter
        this.selectedItems = [];
        this.option = [];

        // Add Parameter like source key like all the key
        if (this.addParameter) {
          this.filterService.duplicateParameter[this.addParameter] = this.stringifyMode(this.selectedItems, this.addParameter)
        }
        this.filterService.allOptionData['multi'][this.field.key] = this.option
        this.filterService.selectedItem['multi'][this.field.key] = this.selectedItems
        this.filterService.previousSelectedItems[this.field.key] = this.selectedItems

        this.field.formControl?.setValue({ [this.key as string]: 'null' })
        this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
      }
    }, err => {
      console.error("Error " + err)
      this.selectedItems = []
      this.option = []

      // Add Parameter like source key like all the key
      if (this.addParameter) {
        this.filterService.duplicateParameter[this.addParameter] = this.stringifyMode(this.selectedItems, this.addParameter)
      }

      this.filterService.allOptionData['multi'][this.field.key] = this.option
      this.filterService.selectedItem['multi'][this.field.key] = this.selectedItems
      this.filterService.previousSelectedItems[this.field.key] = this.selectedItems

      this.field.formControl?.setValue({ [this.key as string]: 'null' })
      this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
    }));
  }

  queryLoaded(query: any) {
    let spltQuery = query.toString().split(',');
    let comparedArrayW = this.option.filter((obj2: any) => spltQuery.some((obj1: any) => obj2[this.field.key].toString().toLowerCase() == obj1.toString().toLowerCase()));
    return comparedArrayW || []
  }
  
  stringifyMode(selectedItems:any, addParameter:any) {
    if (selectedItems?.length == this.option.length && this.selectAllAsNull) return null;
    if (selectedItems?.length == 0) return null;
    let data = ''

    for (let index = 0; index < selectedItems.length; index++) {
      data += selectedItems[index][addParameter] + ','
    }

    return data.slice(0, data.lastIndexOf(","));
  }

  excludeMatchingElements(arr1: any[], arr2: any[]): any[] {
    return arr1.filter((item1: any) => !arr2.some((item2: any) => item2[this.field.key] == item1[this.field.key]));
    // Customize the condition based on the properties you want to compare
  }


  // User State 
  userStoredData() {
    let userStateData: any = this.userActivityService.getData()
    // Object into Array of Object
    let selectedOption: any
    let comparedArray: any = [];

    let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
    let findData: any = updatesObj?.find((o: any) => o.key == this.key as string)


    if (findData) {
      if (findData.value.length) {

        comparedArray = findData['value']?.filter((obj1: any) => this.option.some((obj2: any) => obj2[this.key as string].toString() == obj1[this.key as string].toString()));
        if (comparedArray.length > 0) {
          selectedOption = comparedArray
        } else {
          selectedOption = this.option
        }
      } else {
        selectedOption = this.findObjectsById(findData.value[this.key as string])
      }
    } else {
      selectedOption = this.option;
    }
    return selectedOption
  }

  // Find Data using by ID
  findObjectsById(idToFind: any) {
    let myArray: any = []
    let selectedOption: any
    let fnd = this.option.find((o: any) => o[this.key as string] == idToFind)

    if (fnd) {
      myArray.push(fnd)
      selectedOption = myArray
    } else {
      selectedOption = this.option
    }

    return selectedOption
  }

  // Set Previous to get from service
  setPreviousItems() {
    this.filterService.previousSelectedItems[this.field.key] = this.selectedItems
    this.isChecked = (this.selectedItems.length == this.option.length)
    this.selectAllLabel = this.isChecked ? 'Select All' : 'Select All';
  }

  // Select All function
  selectAll() {
    this.selectedItems = []
    if (this.isChecked)
      this.option.map((o: any) => this.selectedItems.push(o));
    // if (this.selectedItems)
    // this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
  }

  selectAllClick() {
    this.isChecked = !this.isChecked
    this.isAddChecked = false;
    this.selectAllLabel = this.isChecked ? 'Select All' : 'Select All';
    // this.excelBasedFilter()
    if(this.isChecked) {
      this.selectedItems = [];
      
      // this.option.map((m:any, index:any)=> {
      //   if(index>=this.userCanSelect) {
      //     return Object.assign(m, {disabled: true})
      //   } else {
      //     const obj = Object.assign(m, {disabled: false}) 
      //     this.selectedItems.push(obj);
      //     return obj
      //   }
      // })

      this.selectedItems = [...this.option]
    } else {
      this.selectedItems = []
    }

    if(this.option.length==this.selectedItems.length) {
      this.selectedAll = true;
      this.selectAllBtnSelected = true;
      this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
    } else {
      if(this.selectedItems.length==0) {
        this.selectedAll = true;
        this.selectAllBtnSelected = true;
        this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
      } else {
        this.selectedAll = false;
        this.selectAllBtnSelected = false;
        this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
      }
    }
  }

  // Possible Selection
  possibleSelection() {
    
    this.selectAllBtnSelected = false;
    this.selectedAll = false;
    
    if(this.enteredText) {
      let option = this.defaultAllOption.filter((f: any) => f[this.labelProp].toString().toLowerCase().includes(this.enteredText));
      if(option) {
        this.selectedItems = [...option];
        return;
      }
    }
    this.selectedItems = [...this.option];
  }

  selectAlternative() {
    // debugger
    if(this.selectedItems.length!=this.option) {
      const alterData = this.option.filter((f: any) => !this.selectedItems.find((o: any) => o[this.labelProp] === f[this.labelProp]));
      this.selectedItems = [];
      this.selectedItems = [...alterData];
    }
  }

  selectExcluded() {
    if(this.selectedItems.length!=this.option) {
      const alterData = this.option.filter((f: any) => !this.selectedItems.find((o: any) => o[this.labelProp] === f[this.labelProp]));
      this.selectedItems = [];
      this.selectedItems = [...alterData];
    }
  }

  // Select Add CLick in Excel Filter
  selectAddClick() {
    this.isAddChecked = !this.isAddChecked

    let previousSelect: any = this.filterService.previousSelectedItems[this.field.key]
    if (this.isAddChecked) {
      if (previousSelect.length > 0) {
        this.selectedItems = previousSelect.concat(this.selectedItems);
        this.selectedItems = this.removeDublicates(this.selectedItems);
        this.selectedItems = this.option.filter((f:any)=> this.selectedItems.find((o:any)=> o[this.labelProp] == f[this.labelProp]))
        
      }
    } else {
      this.selectedItems = this.removeSelectedObjects(this.selectedItems, previousSelect);
      this.selectedItems = this.removeDublicates(this.selectedItems)
    }
    if (this.selectedItems.length == 0) {
      this.isChecked = false;
    }

    if((this.option.length==this.selectedItems.length)&& (this.defalutOption.length == this.option.length)) {
      this.selectedAll = true;
      this.selectAllBtnSelected = true;
      this.isChecked = true;
      this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
    } else {
      if(this.selectedItems.length==0) {
        this.selectedAll = true;
        this.selectAllBtnSelected = true;
        this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
      } else {
        this.selectedAll = false;
        this.selectAllBtnSelected = false;
        this.isChecked = false;
        this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
      }
    }


  }

  // Excel Based Filter
  excelBasedFilter() {
    if (this.enteredText) {
      let option = this.option.filter((f: any) => f[this.labelProp].toString().toLowerCase().includes(this.enteredText))
      if (this.isChecked) {
        this.selectedItems = [...option];
      } else {
        this.selectedItems = []
      }
    } else {
      this.selectedItems = [];
      if (this.isChecked) {
        this.selectedItems = [...this.option]
      }
    }

    // this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
  }

  removeSelectedObjects(mainArray: any[], objectsToRemove: any[]): any[] {
    return mainArray.filter(obj => !objectsToRemove.some(remObj => remObj[this.field.key].toString().toLowerCase() == obj[this.field.key].toString().toLowerCase()));
  }

  removeDublicates(data: any) {
    return Array.from(new Set(data.map((item: any) => item[this.field.key]))).map(id => {
      return data.find((item: any) => item[this.field.key] === id);
    });
  }

  disabledOption() {
    if(this.selectedItems.length >= this.userCanSelect) {
      this.option = _.map(this.option, item => {
        
        if(this.selectedItems.length==0) return _.assign({}, item, { disabled: false });
        
        const selectedItem = this.selectedItems.find((f:any)=> f[this.labelProp] == item[this.labelProp]);
        if(selectedItem) {
          return _.assign({}, item, { disabled: false });
        } else {
          return _.assign({}, item, { disabled: true });
        }
      });
    } else {
      this.option = _.map(this.option, item => _.assign({}, item, { disabled: false }));
    }
  }

  selectOption() {


    // Disabled Functions
    // this.disabledOption();

    this.isChecked = (this.selectedItems.length == this.option.length)
    this.selectAllLabel = this.isChecked ? 'Select All' : 'Select All';
    if (this.selectedItems.length == 0)
      this.isOpen = true;
    else
      this.isOpen = null

    if(this.option.length==this.selectedItems.length) {
      this.selectedAll = false;
      this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
      // this.selectAllBtnSelected = true;
    } else {
      if(this.selectedItems.length==0) {
        this.selectedAll = true;
        this.isAddChecked = false;
        this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
        // this.selectAllBtnSelected = true;
      } else {
        this.selectedAll = false;
        this.selectAllBtnSelected = false;
        this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
      }
    }


    // this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
  }

  getDifference(array1: any, array2: any) {
    return array1.filter(
      (object1: any) => !array2.some(
        (object2: any) => object1[this.key as string] == object2[this.key as string]
      ),
    );
  }

  getDeSelectedData(): any {
    const data: any = sessionStorage.getItem('deSelectedFilter');
    return JSON.parse(data) || this.deSelectedData;
  }

  deSelectedData: any = {
    'deSelect': {}
  }

  differenceOfDetails(data: any) {
    this.deSelectedData = this.getDeSelectedData();

    // Object into Array of Object
    let updatesObj: any = Object.entries(data).map(([key, value]) => ({ key, value }));

    for (let i = 0; i < updatesObj.length; i++) {
      if (updatesObj[i].key == "deSelect") {
        let Obj: any = Object.entries(updatesObj[i].value).map(([key, value]) => ({ key, value }));
        Obj.forEach((res: any) => {
          this.deSelectedData[updatesObj[i].key][res.key] = res.value
        })
      } else {
        this.deSelectedData[updatesObj[i].key] = updatesObj[i].value
      }
    }

    const serializedData: any = JSON.stringify(this.deSelectedData);
    sessionStorage.setItem('deSelectedFilter', serializedData);

  }

  applyFilter(ctrl: any) {
    
    if(this.selectedItems.length>=this.userCanSelect) {

    }

    let key = this.key as string
    let fndChild: any = this.filterService.globalFilterFields.find((res: any) => key == res.parentKey)


    if (fndChild) {
      this.model[fndChild.key] = ''

      // User State
      let ab = { [fndChild.key]: [] }
      this.userActivityService.setData({ "selectedFilters": ab })

      delete this.filterService.previousSelectedItems?.[fndChild.key]
    }

    this.navigationService.filterSingleState(key);
    
    this.setPreviousItems();
    this.isOpen = null

    if (this.addParameter) {
      this.filterService.duplicateParameter[this.addParameter] = this.stringifyMode(this.selectedItems, this.addParameter)
    }

    // User State
    let ab = { [this.key as string]: this.selectedItems }
    this.userActivityService.setData({ "selectedFilters": ab })

    // user state for single selection
    if (this.cardId == 'multi') {
      let ab = { [this.key as string]: this.selectedItems }
      this.userActivityService.setDataKpiSingle({ "selectedFilters": ab })
    }

    // from network chart => status code and patient status
    let netWork: any = sessionStorage.getItem('net_status_flr')
    if (netWork) {
      netWork = JSON.parse(netWork)

      let updatesObj: any = Object.entries(netWork).map(([key, value]) => ({ key, value }));
      let findData: any = updatesObj.find((o: any) => o.key == key)

      if (findData) {
        delete netWork[findData.key]
        sessionStorage.setItem('net_status_flr', JSON.stringify(netWork))
      }
    }

    //  // KPI Break Down
    // let kpiCon:any = sessionStorage.getItem('selectedKPIcontrol')

    // if(kpiCon) {
    //   kpiCon = JSON.parse(kpiCon)

    //   let updatesObj: any = Object.entries(kpiCon).map(([key, value]) => ({ key, value }));
    //   let findKpi: any = updatesObj.find((o: any) => o.key == key)

    //   if(findKpi) {
    //     delete kpiCon[findKpi.key]
    //     sessionStorage.setItem('selectedKPIcontrol', JSON.stringify(kpiCon))
    //   }
    // }

    this.filterService.filterApplyBtn();

    this.filterService.selectedItem['multi'][this.field.key] = this.selectedItems;
    
    if(this.selectedItems.length==this.option.length) {
      this.field.formControl?.setValue(null);
      this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
    } else {
      this.field.formControl?.setValue(this.selectedItems)
      this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
    }

    // this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp);
    ctrl.close();
  }

  // drop down close to get details
  onClose() {
    this.firstTime = true;
    this.isAddChecked = false;
    this.enteredText = '';
    
    this.option = [...this.defalutOption];
    this.selectAllBtn=true;

    if (this.filterService.previousSelectedItems?.[this.field.key].length == 0) {
      this.isChecked = true
      this.selectedItems = this.option
    } else {
      this.selectedItems = this.filterService.previousSelectedItems?.[this.field.key]
      this.isChecked = (this.selectedItems.length == this.option.length)
      this.selectAllLabel = this.isChecked ? 'Select All' : 'Select All';
    }

    if(this.option.length == this.selectedItems.length) {
      this.isChecked = false;
      this.selectedItems = [];
      this.selectedAll = true;
      this.selectAllBtnSelected = true;
      this.filterService.setBadgeValue(this.key as string, 'All', 'newMultiSelect', this.option, 'multiSelection', this.showBy, this.labelProp)
    } else {
      this.selectedAll = false;
      this.selectAllBtnSelected = false;
      this.filterService.setBadgeValue(this.key as string, this.selectedItems.length, this.option.length, this.selectedItems, 'multiSelection', this.showBy, this.labelProp)
    }
  }

  overlayClick(event: MouseEvent) {
    event.stopPropagation();  // Prevent the click from closing the ng-select
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe();
    }
  }
}
